import * as React from "react";
import { Layout } from "app/components/layout";
import { Header1 } from "app/components/header";
import { Footer1 } from "app/layouts/footer1";

const TermsAndConditions: React.FC = () => (
  <Layout>
    <div className="bg-white pb-8 sm:pb-12 lg:pb-12 font-sans">
      <Header1 />
      <div className="max-w-3xl m-9">
        <h1>Terms and conditions</h1>
        <h2>Terms and conditions</h2>
        <p>
          You are now connected to the Qameta Software s.r.o. public Internet Site. This page
          (together with the documents referred to on it) tells you the conditions of on which you
          may make use of our Qameta Software s.r.o. website (https://qameta.cz) referred herein
          after as “the Website”. Please read these Conditions of use carefully before you start to
          use the Website.
        </p>
        <h2>Acceptance</h2>
        <p>
          The information and content contained in the Website are provided subject to the following
          Terms and Conditions. Your access to this Website and continued usage constitutes complete
          acceptance of these terms and conditions.
        </p>
        <h2>Purpose of the Website</h2>
        <p>
          The present site is intended to provide information about Qameta Software s.r.o. and its
          subsidiaries and affiliates (“Qameta Software s.r.o. ”), its organization, the products
          and services offered by its divisions, and its share performance. However, this
          information represents only a presentation of Qameta Software s.r.o. ’s products and
          services and is not contractually binding. To enter into any form of contractual
          agreement, please contact the parent company or one of its subsidiaries directly.
        </p>
        <h2>Warranties and liability</h2>
        <p>
          The information provided on this site is free of charge and for informational purposes
          only and does not create a business or professional services relationship between you and
          Qameta Software s.r.o. . Any use you make of the information provided on this site, or any
          site or service linked to by this site, is at your own risk.
        </p>
        <p>
          Although Qameta Software s.r.o. uses reasonable efforts to include accurate and up-to-date
          information in this Website, this Website may include inaccuracies, outdated information
          or typographical errors. Therefore, Qameta Software s.r.o. makes no warranties or
          representations as to the accuracy or completeness of any information contained herein.
          Further, Qameta Software s.r.o. assumes no liability, obligation or responsibility for any
          errors or omissions in the content of this site.
        </p>
        <p>
          The use of the elements of this Website, any information included therein as well as the
          access to this site are of the sole responsibility of the user. Qameta Software s.r.o.
          disclaims any liability for any damage whatsoever, including without limitation the
          indirect, consequential or incidental damages, including but not limited to lost profits
          or revenues, business interruption, loss of data arising out of or in connection with the
          use, inability to use or reliance on any material contained in this site or any linked
          site that may result from access to this site and the use in whole or in part of the
          elements or information contained therein.
        </p>
        <p>
          Qameta Software s.r.o. does not guarantee in any way uninterrupted access to this Website
          as well as the security of the site and the absence of any viruses or other undesirable
          hosts (including in the elements of the Website and in the information which is
          incorporated there).
        </p>
        <h2>Modification</h2>
        <p>
          Qameta Software s.r.o. can modify and update at any moment, and without any kind of
          notice, the content published on this Website or any other contained information.
        </p>
        <h2>Third party websites</h2>
        <p>
          This Website provides links to third-party websites, which are not under the control of
          and are not maintained by Qameta Software s.r.o. . Such links are provided for your
          convenience and do not imply approval or endorsement by Qameta Software s.r.o. of the
          linked sites. Qameta Software s.r.o. has not reviewed any or all of the sites linked to
          this site and is thus not responsible for the content of those sites or any of the
          off-site pages or any other sites linked to those sites.
        </p>
        <h2>Uploading material to the Website</h2>
        <p>
          Any information or request for information that you may send to Qameta Software s.r.o. via
          the site or via e-mail to which the site may give access, is considered non-confidential.
          Qameta Software s.r.o. does not assume any obligation to monitor the information that you
          may post on its Website.
        </p>
        <p>
          By submitting any information or material, you give the Company an unlimited and
          irrevocable license to use, execute, show, modify and transmit such information, Material
          or comments, including any underlying idea, concept or know-how (the term “Material” is
          intended to cover all projects, files or other attachments sent to us). The Company
          reserves the right to use such information in any way it chooses. Qameta Software s.r.o.
          reserves the right at all times, in its sole discretion, to screen content submitted by
          you and to edit, move, delete, and/or refuse to accept any content that in Qameta Software
          s.r.o. ’s judgment violates these Terms of Use or is otherwise unacceptable or
          inappropriate, whether for legal or other reasons.
        </p>
        <p>
          You warrant that any information, materials, projects, files, attachments or comments sent
          to us, other than personal data, that you may transmit to Qameta Software s.r.o. through
          the Website does not infringe intellectual property rights or any other applicable law.
          Such information provided will be treated as non-confidential.
        </p>
        <p>
          By submitting material on this Website, you grant Qameta Software s.r.o. , and anyone
          authorized by Qameta Software s.r.o. , a royalty-free, perpetual, irrevocable,
          non-exclusive, unrestricted, worldwide license to use, copy, modify, transmit, sell,
          exploit, create derivative works from, distribute, and/or publicly perform or display such
          material, in whole or in part, in any manner or medium (whether now known or hereafter
          developed), for any purpose that Qameta Software s.r.o. may choose.
        </p>
        <h2>Applicable Law</h2>
        <p>
          In the event that any portion of these Terms and Conditions is held to be unenforceable,
          the unenforceable portion shall be construed in accordance with applicable law as nearly
          as possible to reflect the original intentions of the parties and the remainder of the
          provisions shall remain in full force and effect.
        </p>
        <p>
          Qameta Software s.r.o. ’s failure to insist upon or enforce strict performance of any
          provision of this Agreement shall not be construed as a waiver of any provision or right.
          Neither the course of conduct between any party referred to herein nor trade practice
          shall act to modify any provision of these Terms and Conditions.
        </p>
        <p>
          These Terms and Conditions shall be governed by and construed in accordance with Czech
          law. Any cause of action against Qameta Software s.r.o. must be commenced in Czech
          Republic.
        </p>
        <h2>Press Releases</h2>
        <p>
          Qameta Software s.r.o. press releases are presented on this Website for historical
          purposes only. The information provided by Qameta Software s.r.o. was accurate at the time
          of release, subject to the disclaimer about forward-looking statements, which was printed
          concurrently with the announcement. Investors should not assume that statements made in
          these documents remain in effect at a later time. Qameta Software s.r.o. does not review
          past postings to determine whether they remain accurate, and information contained in such
          postings may be superseded. Qameta Software s.r.o. disclaims any obligation to update the
          statements or press releases. The financial information that may be accessed through this
          page speaks only as of the particular date or dates of its origination. Such information
          may have become out of date. Qameta Software s.r.o. does not undertake any obligation, and
          disclaims any duty, to update any of the information available through this page. Qameta
          Software s.r.o. ’s future financial performance is subject to various trends, risks and
          uncertainties that could cause actual results to differ materially from those expressed in
          any forward-looking statements. The trends, risks and uncertainties that could affect
          Qameta Software s.r.o. ’s future financial results are discussed more fully in Qameta
          Software s.r.o. ’s reports filed with the AMF, including Qameta Software s.r.o. ’s Annual
          Report for the most recent fiscal year.
        </p>
        <h2>Notice of intellectual property rights</h2>
        <p>
          This website is protected by intellectual property rights and is the exclusive property of
          Qameta Software s.r.o. . Any material that it contains, including, but not limited to,
          text, graphics, information, motifs, logos, designs and databases, pictures, sounds,
          videos, logos, icons or html code is protected under intellectual property law and remains
          Qameta Software s.r.o. or third party’s property. You may use this material for personal
          and non-commercial purposes in accordance with the principles governing intellectual
          property law. Any other use or modification of the content of the Company’s website
          without the Company’s prior written authorization is prohibited. Except where expressly
          stated to the contrary, all intellectual property rights in the contained in this site
          including the domain names, organization and layout of the site and the software used in
          relation to the site are owned by the Qameta Software s.r.o. Qameta Software s.r.o. owns a
          portfolio of registered and unregistered trademarks including but not limited to Qameta
          Software s.r.o. , the Qameta Software s.r.o. logo and the Qameta Software s.r.o. globe are
          registered trademarks of the Qameta Software s.r.o. group.All other trade marks referred
          to on the site are the trademarks of their respective owners and you will require their
          specific authorization should you wish to use any of the trade
        </p>
        <p>
          You may not reproduce or store any part of this site in any other web site, document
          management system or electronic retrieval system (via screen-scraping or otherwise)
          without Qameta Software s.r.o. ’ prior written permission. You must not use any part of
          the materials on this site for commercial purposes without obtaining a licence to do so
          from Qameta Software s.r.o. If you print off, copy or download any part of this site in
          breach of these terms of use, your right to use this site will cease immediately and you
          must, at Qameta Software s.r.o. option, return or destroy any copies of the materials you
          have made. Any rights not expressly granted in this notice are reserved.
        </p>
        <h2>Data Protection and Privacy</h2>
        <p>Please check our Privacy Policy</p>
        <h2>Modification of these terms of use</h2>
        <p>
          Qameta Software s.r.o. can modify at any time and without notice these terms of use and
          therefore encourages you to regularly read the terms of use.
        </p>
        <h2>Legal notices</h2>
        <p>
          This Website is registered and owned by Qameta Software s.r.o., a European public
          limited-liability company, duly organised and validly existing under the laws of Czech
          Republic having its registered office at Nademlejnská 600/1, Hloubětín, 198 00 Praha 9
        </p>
      </div>
      <Footer1 />
    </div>
  </Layout>
);

export default TermsAndConditions;
